import { LayoutService } from '@/services/layout-service'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { prescriptionsPageNavitems, isRenewedOptions, isRenewedOptionsTous, isRenewedFlag, isRenewedFlagPending, PrescriptionSearchModel, prescriptionsPageNoRenewalsNavitems } from '../Constants'
import Alert from '@/components/shared/Alert.vue'
import { getPrescriptionStatusText, getPrestationCategoryName, districts, prescriptionStatusTypes, prestationCategories, prescriptionTypes } from '@/views/Patients/Dossier/Constants'
import { PatientService } from '@/services/patient-service'
import { ErrorService } from '@/services/error.service'
import { PrescriptionService } from '@/services/prescription-service'
import { PatientPrescriptionService } from '@/services/patient-prescription-service'
import { IPrestationRenewable, PrescriptionWithPrestationsSearchResponseModel, PrescriptionWithPrestationsToRenewResponseModel, PrestationsToRenewResponseModel } from '@/models/prescriptions-model'
import { dossierPatientModel } from '@/models/dossier-response-model'
import Commons from '@/components/shared/Helpers/commons'
import { PrescriptionSearchHeader } from '@/views/Patients/Static'
import { siteItems } from '@/views/Administration/Constants'
import PrestationPrescriptionTypeId from '@/components/shared/TableCellRenderer/PrestationPrescriptionTypeId.vue'
import PrescriptionHelpers from '@/components/shared/Helpers/prescription-helpers'
import EditPrestationDialog from '@/components/shared/Dialogs/EditPrestationDialog/EditPrestationDialog.vue'
import { defaultItemsPerPage, defaultItemsPerPageWithoutAll } from '@/shared/constants/Constants'
import { PagedCollection } from '@/components/shared/Helpers/common-models'
import { ModuleAuthorisationManager } from '@/services/module-authorisation-manager'

@Component({
  components: {
    Alert,
    EditPrestationDialog,
    PrestationPrescriptionTypeId
  }
})
export default class Recherche extends Vue {
  private patientsService = PatientService.getInstance()
  private prescriptionService = PrescriptionService.getInstance()
  private patientPrescriptionService = PatientPrescriptionService.getInstance()
  private layoutService = LayoutService.getInstance()
  public prescriptionHeaders = PrescriptionSearchHeader
  public dbDossiers: dossierPatientModel[] = []
  public items: PrescriptionWithPrestationsSearchResponseModel[] = []
  public selectedEditPrescription: PrescriptionWithPrestationsSearchResponseModel | any = {}
  public isLoading = false
  public searchLoading = false
  public showPrescriptionDialog = false
  public isSaving = false

  public showAlert = false
  public alertType: 'success' | 'error' = 'success'
  public globalCheckboxValue = false
  public globalCheckboxIndeterminate = false
  public generating = false
  public searchModel: PrescriptionSearchModel = { renewed: isRenewedOptionsTous }

  public districts = districts
  public status = prescriptionStatusTypes
  public prestationCategories = prestationCategories
  public prescriptionTypes = prescriptionTypes
  public allSites = siteItems
  public professionalDoctorsList: { id: number; fullname: string }[] = []
  errorMessages: string[] = []
  public prestationHeaders: any[] = []
  public isRenewedOptions = isRenewedOptions
  public isRenewedFlag = isRenewedFlag
  public showPrestationDialog = false
  public selectedEditPrestation: IPrestationRenewable | any = {}
  public defaultItemsPerPageWithoutAll = defaultItemsPerPageWithoutAll
  public defaultItemsPerPage = defaultItemsPerPage
  public options: any = {}
  public totalSize = 0
  public pageCount = 0
  public firstSearch = true
  public canPaginationTriggerSearch = false

  public searchPatient = ''
  public selectedPatient?: dossierPatientModel
  public readyPatients = true

  public mounted () {
    if (ModuleAuthorisationManager.HasAccess('prescription.createPrescriptionRenewals')) {
      this.layoutService.updateDrawerList(prescriptionsPageNavitems)
    } else {
      this.layoutService.updateDrawerList(prescriptionsPageNoRenewalsNavitems)
    }
    this.getDoctors()

    this.prestationHeaders = [
      {
        text: 'Prestation',
        value: 'categeoryTypeId',
        formatter: getPrestationCategoryName
      },
      {
        text: 'Début',
        value: 'dateStart',
        formatter: Commons.TransformDateFormat
      },
      {
        text: 'Échéance',
        value: 'dateEnd',
        formatter: Commons.TransformDateFormat
      },
      {
        text: 'Type',
        value: 'prescriptionTypeId'
      },
      {
        text: 'À renouveler',
        value: 'isRenewable'
      },
      {
        text: 'Renouvelé',
        value: 'isRenewed',
        formatter: this.renewedState
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
        align: 'right'
      }
    ]
  }

  @Watch('searchPatient')
  public searchPatientChanged (v) {
    if (v && v.length > 2) {
      this.refreshPatients()
    } else {
      this.dbDossiers = []
    }
  }

  public get patients () {
    if (this.selectedPatient?.fullName) {
      return [...this.dbDossiers, this.selectedPatient].sort((a, b) => (a.fullName.toLocaleLowerCase()).localeCompare(b.fullName.toLocaleLowerCase()))
    }
    return this.dbDossiers
  }

  public patientChanged (e) {
    this.selectedPatient = this.dbDossiers.find(g => g.guid.toLowerCase() === e?.guid?.toLowerCase())
  }

  public refreshPatients () {
    // bail early if a search is already in progress
    if (!this.readyPatients) {
      return
    }
    this.readyPatients = false
    this.patientsService.getActivePatients(this.searchPatient).then((ds: any) => {
      this.dbDossiers = ds.value
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    }).finally(() => {
      this.readyPatients = true
    })
  }

  public tableOptionsChanged () {
    if (this.canPaginationTriggerSearch) {
      this.refreshPrescriptions()
    }
  }

  public async buttonSearch () {
    this.canPaginationTriggerSearch = true
    await this.refreshPrescriptions()
    this.options.page = 1
  }

  private async getDoctors () {
    this.searchLoading = true
    const response = await this.patientPrescriptionService.getDoctors()
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      })
    this.searchLoading = false
    this.professionalDoctorsList = response as { id: number; fullname: string }[]
  }

  public async refreshPrescriptions () {
    // prevent multiple searches from being sent, since searches can be issued from clicking the search button, v-data-table options changing, ...
    if (this.isLoading) {
      return
    }
    if (this.firstSearch) {
      this.firstSearch = false
      this.options.sortBy = ['dateStart', 'patientName']
      this.options.sortDesc = [true, false]
    }
    this.isLoading = true
    const payload = {
      ...this.searchModel,
      patientId: this.searchModel.patient?.guid
    }
    delete payload.patient
    await this.prescriptionService.searchPrescriptions(payload, this.options).then((p: PagedCollection<PrescriptionWithPrestationsSearchResponseModel>) => {
      this.items = p.value
      this.totalSize = p.size
      Commons.setRowClasses(this.items)
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    }).finally(() => {
      this.isLoading = false
    })
  }

  public getStatusText (id: number) {
    return getPrescriptionStatusText(id)
  }

  public getPrescriptionType (prescriptionTypeId: number) {
    return this.prescriptionTypes.find(x => x.value === Number(prescriptionTypeId))?.text
  }

  public canBeRenewed (prestation: PrestationsToRenewResponseModel) {
    if (prestation.isRenewable ?? false) {
      return Commons.canPrestationTRBeRenewed(prestation)
    }
    return false
  }

  public editPrescription (prescription: PrescriptionWithPrestationsToRenewResponseModel<PrestationsToRenewResponseModel>) {
    this.selectedEditPrescription = prescription
    this.showPrescriptionDialog = true
  }

  public editPrestation (prestation: IPrestationRenewable) {
    this.selectedEditPrestation = prestation
    this.showPrestationDialog = true
  }

  public canEditPrestation (prestation: PrestationsToRenewResponseModel) {
    return prestation.isRenewed === isRenewedFlagPending
  }

  public renewedState (renewedFlag: number) {
    return PrescriptionHelpers.GetRenewedState(renewedFlag)
  }

  public async updatePrescriptionStatus () {
    this.isSaving = true
    const res = await this.prescriptionService.updatePrescriptionStatus(this.selectedEditPrescription)
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      })
      .finally(() => {
        this.isSaving = false
      })
    if (res) {
      this.closePrescriptionDialog()
    }
  }

  public hideAlert () {
    this.errorMessages = []
  }

  public resetSearch () {
    this.searchModel = { renewed: isRenewedOptionsTous }
  }

  public closePrescriptionDialog () {
    this.selectedEditPrescription = {}
    this.refreshPrescriptions()
    this.showPrescriptionDialog = false
  }

  public closePrestationDialog () {
    this.selectedEditPrestation = {}
    this.refreshPrescriptions()
    this.showPrestationDialog = false
  }

  public displayPatient (item: dossierPatientModel) {
    return Commons.autocompleteDisplayPatient(item, this.searchPatient)
  }
}
